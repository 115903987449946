import { useState, useEffect, useRef } from "react";
import { GrClose } from "react-icons/gr";

function MultipleSelectTopLabeled(props) {
    const [optionVisible, setOptionVisible] = useState(false);
    const [optionData, setOptionData] = useState(props.optionData);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const [inputValue, setInputValue] = useState("");
    const inputRef = useRef(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        setOptionData(props.optionData);
        setFilteredOptions(props.optionData);
    }, [props.optionData]);

    function handlerRemoveFromSelection(element) {
        props.setState((old) => {
            const index = old.indexOf(element);
            if (index > -1) {
                const newState = [...old.slice(0, index), ...old.slice(index + 1)];
                props.onChange && props.onChange(newState);
                return newState;
            }
            return old;
        });
    }

    function handlerAddToSelection(element) {
        props.setState((old = []) => {
            if (old.length < 2 && !old.includes(element)) {
                const newState = [...old, element];
                props.onChange && props.onChange(newState);
                return newState;
            }
            return old;
        });
        setOptionVisible(true);
    }

    function filterOptionData(e) {
        const searchTerm = e.target.value;
        let filteredData = [];

        if (searchTerm !== "") {
            filteredData = optionData.filter((element) =>
                element.toLowerCase().includes(searchTerm.toLowerCase())
            );
        } else {
            filteredData = optionData;
        }

        setFilteredOptions(filteredData);
        setInputValue(searchTerm);
        setHighlightedIndex(-1);
    }

    function handleKeyDown(e) {
        if (!optionVisible) return;

        switch (e.key) {
            case "ArrowDown":
                setHighlightedIndex((prevIndex) =>
                    Math.min(prevIndex + 1, filteredOptions.length - 1)
                );
                break;
            case "ArrowUp":
                setHighlightedIndex((prevIndex) =>
                    Math.max(prevIndex - 1, 0)
                );
                break;
            case "Enter":
                e.preventDefault();
                if (highlightedIndex >= 0 && filteredOptions[highlightedIndex]) {
                    handlerAddToSelection(filteredOptions[highlightedIndex]);
                }
                break;
            case "Escape":
                setOptionVisible(false);
                break;
            default:
                break;
        }
    }

    function handleFocus() {
        setOptionVisible(true);
    }

    function handleClickOutside(event) {
        if (
            inputRef.current &&
            !inputRef.current.contains(event.target) &&
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target)
        ) {
            setOptionVisible(false);
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div
            className="flex flex-col gap-1 grow-0 relative"
            ref={inputRef}
            onKeyDown={handleKeyDown}
            tabIndex={0}
        >
            <label htmlFor="id" className="text-xs">
                {props.label}
                {props.required && <span className="ml-1 text-red-500">*</span>}
            </label>

            <input
                type={props.type || "text"}
                className="p-2 border rounded grow min-w-[100px] max-h-[35px] text-xs placeholder:text-xs"
                placeholder={props.placeholder}
                disabled={props.disabled}
                value={inputValue}
                onChange={filterOptionData}
                onFocus={handleFocus}
                ref={inputRef}
            />

            {optionVisible && (
                <div
                    ref={dropdownRef}
                    className="absolute top-full left-0 mt-1 bg-white z-10 w-full border rounded shadow-lg"
                >
                    <div className="py-1 bg-white border border-gray-400 rounded">
                        {filteredOptions?.map((element, index) => (
                            <div
                                key={index}
                                className={`px-2 text-sm text-black cursor-pointer hover:bg-first hover:text-white ${
                                    index === highlightedIndex
                                        ? "bg-first text-white"
                                        : ""
                                }`}
                                onClick={() => handlerAddToSelection(element)}
                                onMouseEnter={() => setHighlightedIndex(index)}
                            >
                                {element}
                            </div>
                        ))}
                    </div>
                </div>
            )}

            <div
                className="flex flex-wrap gap-1 mt-1"
                style={{ maxWidth: props.maxWidth }}
            >
                {props.state?.map((element, index) => (
                    <div
                        className="inline-flex flex-row items-center gap-2 px-2 py-1 text-xs bg-gray-300 rounded"
                        key={index}
                    >
                        <div>{element}</div>
                        <div
                            className="cursor-pointer"
                            onClick={() => handlerRemoveFromSelection(element)}
                        >
                            <GrClose />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default MultipleSelectTopLabeled;
